.loader {
    background: white;
    border: 1px solid #ddd;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin: 10px;
    width: 350px;
    overflow: hidden;
    text-align: left;
    animation: pulse 1.5s infinite ease-in-out;
  }
  
  .image-placeholder {
    width: 100%;
    height: 200px;
    background: #f0f0f0;
  }
  
  .info-placeholder {
    padding: 15px;
  }
  
  .line-placeholder {
    height: 10px;
    background: #f0f0f0;
    border-radius: 5px;
    margin: 5px 0;
  }
  
  .line-placeholder.short {
    width: 50%;
  }
  
  .line-placeholder.medium {
    width: 75%;
  }
  
  .line-placeholder.long {
    width: 100%;
  }
  
  @keyframes pulse {
    0% {
      background-color: #a8a8a8;
    }
    50% {
      background-color: #dacbcb;
    }
    100% {
      background-color: #998888;
    }
  }
  