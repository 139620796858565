/* Complete.css */

body {
    font-family: 'Roboto', sans-serif;
    background-color: #f5f5f5;
    margin: 0;
    padding: 0;
  }
  
  .confirmation-container {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    padding: 20px;
  }
  
  .confirmation-card {
    background-color: #ffffff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    width: 100%;
    max-width: 600px;
    padding: 40px;
    text-align: center;
  }
  
  .success-animation {
    width: 150px; /* Default size for desktop */
    height: auto;
    margin: 0 auto 20px auto;
  }
  
  .confirmation-card h1 {
    font-size: 2em;
    color: #333;
    margin-bottom: 20px;
  }
  
  .confirmation-details {
    text-align: left;
    margin-bottom: 20px;
  }
  
  .confirmation-details p {
    font-size: 1.1em;
    margin: 10px 0;
  }
  
  .confirmation-details strong {
    color: #555;
  }
  
  .services-booked {
    text-align: left;
  }
  
  .service-item {
    background-color: #f9f9f9;
    border: 1px solid #e0e0e0;
    border-radius: 5px;
    padding: 15px;
    margin: 10px 0;
  }
  
  .service-item p {
    margin: 5px 0;
  }
  
  @media (max-width: 768px) {
    .confirmation-card {
      padding: 20px;
    }
  
    .success-animation {
      width: 100px; /* Smaller size for mobile */
      height: auto;
    }
  
    .confirmation-card h1 {
      font-size: 1.5em;
    }
  
    .confirmation-details p {
      font-size: 1em;
    }
  }
  


/* 

  .receipt-container {
    max-width: 600px;
    margin: 0 auto;
    font-family: Arial, sans-serif;
    padding: 20px;
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #ddd;
    padding-bottom: 10px;
  }
  
  .header-left h1 {
    margin: 0;
    font-size: 24px;
    color: #000;
  }
  
  .header-right p {
    margin: 0;
    font-size: 14px;
    color: #777;
  }
  
  .thanks-section {
    text-align: center;
    padding: 20px 0;
    border-bottom: 1px solid #ddd;
  }
  
  .thanks-section h2 {
    margin: 0;
    font-size: 22px;
    color: #000;
  }
  
  .thanks-section p {
    margin: 5px 0 10px;
    font-size: 16px;
    color: #555;
  }
  
  .car-icon {
    width: 50px;
    height: auto;
  }
  
  .total-section {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 0;
    border-bottom: 1px solid #ddd;
  }
  
  .total-section h2 {
    margin: 0;
    font-size: 24px;
    color: #000;
  }
  
  .details-section {
    padding: 20px 0;
    border-bottom: 1px solid #ddd;
  }
  
  .details-row {
    display: flex;
    justify-content: space-between;
    padding: 5px 0;
  }
  
  .payment-section {
    display: flex;
    align-items: center;
    padding: 20px 0;
    border-bottom: 1px solid #ddd;
  }
  
  .payment-icon {
    width: 24px;
    height: auto;
    margin-right: 10px;
  }
  
  .payment-section a {
    margin-left: 10px;
    color: #0000EE;
    text-decoration: none;
  }
  
  .note-section {
    padding: 20px 0;
    border-bottom: 1px solid #ddd;
  }
  
  .note-section p {
    margin: 0;
    font-size: 14px;
    color: #555;
  }
  
  .note-section a {
    color: #0000EE;
    text-decoration: none;
  }
  
  .driver-section {
    padding: 20px 0;
    text-align: center;
  }
  
  .driver-section h3 {
    margin: 0 0 10px;
    font-size: 20px;
    color: #000;
  }
  
  .driver-info {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .profile-pic {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    margin-right: 20px;
  }
  
  .driver-details p {
    margin: 0;
    font-size: 14px;
    color: #555;
  }
  
  .star-icon {
    color: gold;
  }
  
  .rate-button {
    background-color: #0000EE;
    color: #fff;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    font-size: 14px;
    cursor: pointer;
  }
  
  .rate-button:hover {
    background-color: #0000CC;
  }
   */