.container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-color: #f5f5f5;
  padding: 20px;
}

.team-container {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
}

.card {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #ffffff;
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  padding: 16px;
  width: 250px;
  text-align: center;
}

.card-header {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
}

.avatar {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 16px;
}

.name {
  font-weight: bold;
  color: #333;
}

.email {
  color: #777;
}
