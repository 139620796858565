
.order-summarynew {
  text-align: left;
}

.order-summarynew h2 {
  margin: 0;
}

.location {
  color: gray;
  font-size: 14px;
  margin-bottom: 20px;
}

.sitem {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.sitem-details {
  display: flex;
  align-items: center;
  width: 100%;
}

.sitem-details img {
  border-radius: 20%;
  width: 50px;
  height: 50px;
  margin-right: 10px;
}

.sitem-info {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
}

.service {
  margin: 0;
  margin-top: 5px;
}
.servicefirst {
  position: absolute;
  left:70px;
}
.sitem-price {
  align-self: flex-end;
  font-weight: bold;
  position: relative;
  bottom: 10px;
}

.additional-services {
  font-size: 14px;
  color: gray;
}

.additional-services p {
  display: flex;
  justify-content: space-between;
  margin: 0 0 10px;
}

.appointment-details {
  font-size: 14px;
  color: gray;
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}

.date-time {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.subtotal-container {
  width: 100%;
}

.subtotal {
  display: flex;
  justify-content: space-between;
  font-weight: bold;
  margin-top: 20px;
}

.price {
  color: black;
}
.pricepp {
  color: black;
  position: relative;
  bottom: 10px;
}

@media (max-width: 768px) {
  .cart-container {
      padding: 10px;
  }

  .item-details img {
      width: 40px;
      height: 40px;
  }

  .item-price {
    margin-left: auto;
}

.price {
  color: black;
  margin-right: 30px;
  margin-left: 46px;
}
.duration{
  margin-right: 30px;
  margin-left: 46px;
}
}



.schedule-container {
    display: flex;
    justify-content: space-between;
    padding: 20px;
  }
  /* General styles for slots */
.slot-container {
  display: inline-block;
  text-align: center;
  margin: 5px;
}
.hidden {
  display: none;
}

.slot-button {
  background-color: black;
  color: rgb(0, 0, 0);
  border-radius: 50%;
  width: 45px;
  height: 45px;
  text-align: center;
  align-items: center;
  font-size: 13px;
}

.slot-button.selected {
  border: 2px solid grey;
}

.slot-day {
  margin-left: 30px;
  margin-top: 10px;
  color: gray;
}

.slot-day.selected {
  color: black;
  font-weight: bold;
}

/* Styles for mobile devices */
@media (max-width: 600px) {
  .slot-button {
    width: 40px;
    height: 40px;
    font-size: 14px;
  }
  .slot-container {
    display: inline-block;
    text-align: center;
    margin: 1px;
    width: 13%;
  }
  .slot-day {
    position: relative;
    right: 15px;
  }


}

/* Styles for desktop devices */
@media (min-width: 601px) {
  .slot-button {
    width: 50px;
    height: 50px;
    font-size: 15px;
    padding: 10px;
    margin-right: 40px;
  }

  .slot-day {
    margin-right: 40px;
  }
}

  /* General styles for the slots */
.slots-container button {
  border: 1px solid gray;
  border-radius: 15px;
  padding: 10px 20px;
  margin: 5px;
  font-size: 10px;
  cursor: pointer;
}

.slots-container button.selected {
  background-color: black;
  color: white;
}
.time-selection-container {
  display: flex;
  align-items: center;
  margin-bottom: 30px;
}

.time-selection-heading {
  margin: 0px 250px 0px 0px;
  padding-right: 10px;
}

/* Styles for mobile devices */
@media (max-width: 600px) {
  .slots-container button {
    font-size: 18px;
    padding: 8px 16px;
  }
  .time-selection-container {
    display: flex;
    align-items: center;
    margin-bottom: 30px;
  }
  
  .time-selection-heading {
    margin: 0px 110px 0px 0px;
    padding-right: 10px;
  }
  
}

/* Styles for desktop devices */
@media (min-width: 601px) {
  .slots-container button {
    font-size: 15px;
    padding: 12px 24px;
  }
  .cart-summary-mobile {display: none;}
}

  .date-picker {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
  }
  
  .date-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 5px;
  }
  
  .date-button {
    background: none;
    border: 1px solid #ddd;
    border-radius: 50%;
    padding: 10px;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s;
  }
  
  .date-button.selected {
    background-color: black;
    color: white;
  }
  
  .date-weekday {
    margin-top: 5px;
    font-size: 12px;
  }
  
  .slot-picker {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-bottom: 20px;
  }
  
  .slot-button {
    background: none;
    border: 1px solid #ddd;
    padding: 10px;
    margin: 5px;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s;
  }
  .cart-item-name{
    margin-top: -15px;
  }
  .slot-button.selected {
    background-color: black;
    color: white;
  }
  
  .slot-button.booked {
    background-color: blue;
    color: white;
    cursor: not-allowed;
  }
  .cart-item-details {
    font-size: 13px;
    color: #333;
    margin-top: 5px;
  }
  
  .cart-item-duration {
    font-size: 13px;
    color: #666;
    margin-left: 20px;
  }
  
  .selectedserviceMobile .cart-item-details,
  .selectedserviceMobile .cart-item-duration {
    display: inline-block;
    margin-right: 10px;
  }
  
  .order-summary {
    /* border: 1px solid #ddd; */
    padding: 20px;
    border-radius: 8px;
    width: 350px;
    align-self: flex-start;
  }
  
  .order-summary h3 {
    margin-top: 0;
    font-size: 1.5rem;
  }
  
  .order-summary p,
  .order-summary li {
    margin: 0;
  }
  
  .order-summary .professional-info {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .order-summary .professional-info img {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    margin-right: 10px;
  }
  
  .order-summary ul {
    list-style: none;
    padding: 0;
  }
  
  .order-summary ul li {
    border-bottom: 1px solid #ddd;
    padding: 10px 0;
  }
  
  .order-summary ul li:last-child {
    border-bottom: none;
  }
  
  .tip-buttons {
    display: flex;
    justify-content: space-between;
    margin: 20px 0;
  }
  
  .tip-button {
    background: none;
    border: 1px solid #ddd;
    padding: 10px;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s;
    flex: 1;
    margin-right: 5px;
    text-align: center;
  }
  
  .tip-button:last-child {
    margin-right: 0;
  }
  
  .tip-button.selected {
    background-color: black;
    color: white;
  }
  
  .order-summary .subtotal {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    font-size: 1.25rem;
  }
  
  .order-summary .continue-button {
    background-color: black;
    color: white;
    padding: 10px;
    border: none;
    width: 100%;
    cursor: pointer;
    text-align: center;
    font-size: 1.25rem;
    margin-top: 20px;
  }
  .pay-with-card-button {
    background-color: rgb(112, 61, 61);
    color: white;
    border-radius: 2px;
    padding: 10px 20px;
    border: none;
    cursor: pointer;
  }

  .pay-with-cash-button {
    background-color: black;
    color: white;
    border-radius: 2px;
    padding: 10px 20px;
    border: none;
    cursor: pointer;
  }

  .cash-payment-button{
    background-color: white;
    color: black;
    border-radius: 20px;
    padding: 10px 20px;
    border: none;
    cursor: pointer;
    
    border: 1px solid #000000;
  }
    /* Container styles */
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.dateandslot{
  position: relative;
  top: 90px;
  left: 190px;
  width: 100%;

}

@media (max-width: 768px) {
  .dateandslot{
    position: relative;
    right: 150px;
    left: 6px;
    width: 100%;
    top: 20px;
  
  }

 
/* Form Input Styles */
.form-input {
  width: 100%;
  padding: 10px;
  margin: 3px 0;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-sizing: border-box;
}

.form-input-container {
  display: flex;
  justify-content: space-between;
}

.form-input-container .form-input {
  width: 38%;
}
}
  .selectedservice {
    position: relative;
    bottom:0px;
  }
.selectedserviceMobile {
  position: relative;
  bottom:  70px;
}
/* Cart styles for desktop */
.cart-summary-desktop {
  position: fixed;
  right: 150px;
  top: 150px;
  width: 360px;
  background-color: white;
  border-left: 1px solid #ccc;
  padding: 20px;
  height: 70%;
  overflow-y: auto;
  z-index: 1000;
}

/* Cart styles for mobile */
.cart-summary-mobile {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: auto;
  border-top: 1px solid #ccc;
  padding: 10px;
  background-color: rgb(0, 0, 0);
  z-index: 1000;
}

/* Button styles */
.pay-with-card-button {
  background-color: black;
  color: white;
  border-radius: 10px;
  padding: 10px 20px;
  border: none;
  cursor: pointer;
  margin: 10px 0;
}

/* Toggle Switch */
.switch {
  position: relative;
  display: inline-block;
  width: 10px;
  height: 14px;
  margin: 10px 0;
}

.switch input {
  display: none;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: .4s;
  border-radius: 34px;
}

.slider:before {
  position: absolute;
  content: "";
  left: 4px;
  bottom: 4px;
  background-color: white;
  transition: .4s;
  border-radius: 50%;
}

input:checked + .slider {
  background-color: black;
}

input:checked + .slider:before {
  transform: translateX(26px);
}

.pay-with-card-button {
  background-color: black;
  color: white;
  border-radius: 10px;
  padding: 10px 20px;
  border: none;
  cursor: pointer;
  margin: 10px 0;
}

/* Form Input Styles */
.form-input {
  width: 90%;
  padding: 10px;
  margin: 5px 5px;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-sizing: border-box;
}

.form-input-container {
  display: block;
}

.form-input-container .form-input {
  width: 44%;
}

/* Phone Input Container */
.phone-input-container {
  display: flex;
  align-items: center;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 5px;
  width: 92%;
}

.phone-input-container img {
  margin-right: 5px;
}

.phone-input {
  width: 80%;
  border: none;
  outline: none;
  padding: 5px;
}

.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
  margin: 10px 0;
}

.switch input {
  display: none;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: .4s;
  border-radius: 34px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  transition: .4s;
  border-radius: 50%;
}

input:checked + .slider {
  background-color: black;
}

input:checked + .slider:before {
  transform: translateX(26px);
}

.pay-with-card-button {
  background-color: black;
  color: white;
  border-radius: 10px;
  padding: 10px 20px;
  border: none;
  cursor: pointer;
  margin: 10px 0;
}
