/* addservice.css */
.addservicebutton {
 
  }
  
  .addservicebutton.remove {
    background-color: red;
    color: white;
    margin-left: 10px;
  }
  .addservicebutton.edit {
    background-color: rgb(41, 161, 41);
    color: white;
    margin-left: 10px;
  }