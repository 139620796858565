/* ClientOrBusiness.css */

.client-or-business-container {
    display: flex;
    height: 100vh;
  }
  
  .left-section {
    width: 50%;
    padding: 40px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: #fff;
  }
  
  .back-button {
    position: relative;
    right: 350px;
    background: none;
    border: none;
    font-size: 24px;
    cursor: pointer;
    color: black;
  }
  
  .cobh1 {
    font-size: 32px;
    margin-bottom: 20px;
    color: black;
  }
  
  .options {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  .option-button {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #f9f9f9;
    border: 1px solid #c0c0c0;
    border-radius: 8px;
    padding: 20px;
    width: 50%;
    margin-bottom: 20px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .option-button:hover {
    background-color: #e9e9e9;
  }
  
  .option-text .cobh2 {
    margin: 0;
    font-size: 20px;
    color: black;
  }
  
  .option-text p {
    margin: 0;
    color: #555;
  }
  
  .arrowbutton {
    font-size: 24px;
    color: black;
  }
  
  .footer {
    display: flex;
    justify-content: space-between;
  }
  
  .footer-button {
    background: none;
    border: none;
    font-size: 16px;
    color: #888;
    cursor: pointer;
  }
  
  .right-section {
    width: 50%;
    position: relative;
  }
  
  .background-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  /* Hide the right section on mobile devices */
@media (max-width: 768px) {
    .client-or-business-container {
      flex-direction: column;
    }
  
    .left-section {
      width: 100%;
      height: 80vh;
    }
  
    .right-section {
      display: none;
    }

    .cobh1 {
        font-size: 22px;
        margin-bottom: 20px;
      }

      .back-button {
        position: relative;
        right: 100px;
        background: none;
        border: none;
        font-size: 24px;
        cursor: pointer;
        color: black;
      }

      .options {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
      }
      
      .option-button {
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: #f9f9f9;
        border: 1px solid #ddd;
        border-radius: 8px;
        padding: 20px;
        width: 100%;
        margin-bottom: 20px;
        cursor: pointer;
        transition: background-color 0.3s;
      }
      
  }