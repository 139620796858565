.login-container {
  display: flex;
  height: 100vh;
  flex-direction: row;
}

.login-form {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 40px 20px 20px 20px;
  text-align: center; /* Center text alignment */
}

.loginh2 {
  margin-bottom: 0px;
  font-size: 40px;
}

.loginp {
  margin-top: 10px;
}

.social-login {
  margin-bottom: 10px;
  padding: 15px;
  width: 100%;
  max-width: 400px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
}

.facebook {
  background-color: #ffffff;
  color: rgb(0, 0, 0);
  font-weight: 600;
  border: 1px solid #000000;
}

.google {
  background-color: #ffffff;
  color: rgb(0, 0, 0);
  font-weight: 600;
  border: 1px solid #000000;
}

.or-divider {
  text-align: center;
  margin: 20px 0;
}

.logininput {
  margin-bottom: 10px;
  padding: 15px;
  width: 100%;
  max-width: 400px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.continue-btn {
  padding: 15px;
  width: 100%;
  max-width: 450px;
  background-color: #000;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  margin-bottom: 20px;
}

.errorlogin {
  color: red;
  margin-top: 10px;
  font-weight: 700;
  position: relative;
}

.support-links {
  margin-top: 20px;
  text-align: center;
}

.support-links a {
  margin: 0 5px;
  color: #0073e6;
  text-decoration: none;
}

.login-image {
  flex: 1;
  background: url('../MediaStores/loginimage.webp') no-repeat center center;
  background-size: cover;
}

@media (max-width: 768px) {
  .login-container {
    flex-direction: column;
  }

  .login-image {
    display: none;
  }

  .loginh2 {
    font-size: 30px;
  }

  .loginp {
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .errorlogin {
    margin-top: 20px;
  }

  .login-container {
    height: 95vh;
  }
}
