.professional-container {
    padding: 20px;
  }
  
  .professional-content {
    display: flex;
    gap: 20px;
  }
  .Chooseaprofessionalh1 {
    margin: 20px;
  }

  .Chooseaprofessionalh2{
   margin-bottom: 30px;
   margin-top: 20px;
  }
  hr {
    width: 100px;
    margin: 0 auto; /* This centers the hr horizontally */
    border: 1px solid #ddd; 
    margin-bottom: 20px;/* Optional: adds a border to the hr for visibility */
  }
  .professional-list {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    width: 70%; 
    margin: 16px;/* Adjust width as needed */
  }
  
  .professional-card {
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 10px;
    width: 180px;
    height: 300px;
    padding: 20px;
    text-align: center;
    cursor: pointer;
    transition: transform 0.2s;
  }
  
  .professional-card:hover {
    transform: scale(1.05);
  }
  
  .professional-image {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100px;
    overflow: hidden;
    border-radius: 10px;
    margin-bottom: 10px;
  }
  
  .professional-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .placeholder-image {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    background-color: #f0f0f0;
    color: #ccc;
    font-size: 14px;
  }
  
  .professional-info {
    text-align: center;
  }
  
  .professional-info h3 {
    margin: 10px 0;
    font-size: 18px;
  }
  
  .professional-info p {
    color: #888;
  }
  
  .professional-summary {
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 10px;
    width: 30%; /* Adjust width as needed */
    padding: 30px;
  }
  
  .summary-placeholder {
    width: 100%;
    height: 300px;
    background: repeating-linear-gradient(
      45deg,
      #f0f0f0,
      #f0f0f0 10px,
      #fff 10px,
      #fff 20px
    );
  }

  .skeleton-card {
    background-color: #e7e7e7;
    border: 1px solid #ddd1d1;
    border-radius: 10px;
    width: 180px;
    height: 300px;
    padding: 20px;
    text-align: center;
    cursor: pointer;
    transition: transform 0.2s;
  }
  
  .skeleton-card:hover {
    transform: scale(1.05);
  }
  
  .skeleton-image,
  .skeleton-text {
    background-color: #f0f0f0;
    border-radius: 10px;
    margin-bottom: 10px;
  }
  
  .skeleton-image {
    width: 100%;
    height: 100px;
  }
  
  .skeleton-text {
    width: 100%;
    height: 20px;
  }
  
  /* Mobile Styles */
@media (max-width: 768px) {
  .professional-content {
    flex-direction: column;
  }

  .professional-list,
  .professional-summary {
    width: 100%;
  }

  .professional-summary {
    display: none;
  }

  .professional-card {
    width: 45%;
    height: 260px;
  }

  .skeleton-card {
    width: 45%;
    height: 260px;
  }
 
  }