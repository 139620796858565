.dashboard-container {
  display: flex;
  flex-direction: column;
  padding: 20px;
  max-width: 800px;
  margin: 0 auto;
}

h2 {
  font-size: 24px;
  margin-bottom: 20px;
  font-weight: 700;
}

.appointments-section {
  margin-bottom: 40px;
}

.appointments-section h3 {
  font-size: 20px;
  margin-bottom: 10px;
  font-weight: 700;
}

.appointments-list {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.appointment-card {
  display: flex;
  border: 1px solid #ccc;
  border-radius: 8px;
  overflow: hidden;
  background-color: #fff;
  transition: box-shadow 0.3s;
}

.appointment-card:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.appointment-image img {
  width: 400px;
  height: 300px;
  object-fit: cover;
}

.appointment-details {
  flex: 1;
  padding: 20px;
}

.appointment-details h4 {
  font-size: 18px;
  margin: 0 0 10px 0;
  font-weight: 700;
}

.appointment-details p {
  margin: 5px 0;
  color: #000000;
  font-weight: 700;
}

.status {
  display: inline-block;
  padding: 5px 10px;
  border-radius: 12px;
  font-size: 12px;
  font-weight: bold;
  margin-top: 10px;
}

.status.confirmed {
  background-color: #000000;
  color: #fff;
}

.no-appointments {
  text-align: center;
  padding: 40px 0;
}

.search-salons-btn,
.book-again-btn {
  display: inline-block;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  background-color: #0073e6;
  color: #fff;
  cursor: pointer;
  margin-top: 10px;
}

.search-salons-btn:hover,
.book-again-btn:hover {
  background-color: #005bb5;
}

.book-again-btn {
  background-color: #000000;
}

.book-again-btn:hover {
  background-color: #388e3c;
}

/* Loading animation styles */
.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  font-size: 24px;
  color: #000000;
}
.appointment-details {
  display: flex;
  flex-direction: column;
  padding: 20px;
}

/* Media query for mobile devices */
@media (max-width: 600px) {
  .appointment-card {
    flex-direction: column;
  }

  .appointment-image img {
    width: 100%;
    height: auto;
  }
}
