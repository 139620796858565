.time-slot {
  padding: 10px;
  margin: 5px;
  cursor: pointer;
  border: 1px solid #ddd;
  display: inline-block;
}

.time-slot.selected {
  background-color: lightblue;
  color: white;
}
