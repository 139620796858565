@import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.4/css/all.min.css');
/* @import url('https://unpkg.com/tailwindcss@^2.0/dist/tailwind.min.css'); */

.dashboard-container {
  display: flex;
  flex-direction: column;
  height: 100vh;
  font-family: Arial, sans-serif;
  background: #dddada;
  padding: 10px;
}

.dashboard-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.main-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 20px;
  background-color: #dddada;
  border-radius: 10px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
}

.metrics {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  flex-wrap: wrap;
}

.metric-card {
  background-color: #cfcfcf;
  border-radius: 10px;
  padding: 20px;
  width: 43%;
  text-align: center;
  color: #000000;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
}

.metric-card h3 {
  margin-bottom: 10px;
  font-size: 1.5em;
}

.metric-card p {
  font-size: 2em;
  margin: 0;
}

.metric-card span {
  font-size: 0.9em;
}

.positive {
  color: #2ECC71;
}

.negative {
  color: #E74C3C;
}

.welcome-container {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 20px;
}

.profile-picture {
  width: 100px;
  height: 100px;
  margin-right: 15px;
  border-radius: 50%;
  object-fit: cover;
}

.flex-container {
  display: flex;
  flex-direction: column;
  align-items: start;
  flex-grow: 1;
}

.flex-container h3 {
  margin-bottom: 10px;
}

.cashout-info, .actions, .appointments {
  background: #dddada;
  padding: 20px;
  border-radius: 10px;
  margin-bottom: 20px;
  text-align: center;
  width: 100%;
  max-width: 800px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.actions {
  display: flex;
  justify-content: center;
  gap: 20px;
  flex-wrap: wrap;
}

.actions button {
  margin: 10px;
  padding: 15px 30px;
  background-color: #000000;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
  font-size: 1.2em;
  display: flex;
  align-items: center;
}

.actions button:hover {
  background-color: #2980B9;
}

.actions button svg {
  margin-right: 10px;
}

.appointments h3 {
  margin-bottom: 20px;
}

.appointment-list {
  list-style-type: none;
  padding: 0;
}

.appointment-item {
  display: flex;
  align-items: center;
  padding: 15px;
  border-bottom: 1px solid #ddd;
  flex-wrap: wrap;
}

.appointment-date {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 0 0 60px;
  text-align: center;
  font-size: 1.2em;
  font-weight: bold;
  color: #000000;
}

.appointment-details {
  flex: 1;
  padding-left: 20px;
}

.appointment-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.appointment-status {
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 0.9em;
}

.appointment-status.card {
  background-color: #2980B9;
  color: #ffffff;
}

.appointment-status.cash {
  background-color: #ffffff;
  color: #2980B9;
  border: 1px solid #2980B9;
}

.appointment-info {
  margin-top: 5px;
}

.appointment-cost {
  font-size: 1.1em;
  font-weight: bold;
  color: #000000;
}

.confirm-button {
  background-color: #2ECC71;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  margin-right: 10px;
  transition: background-color 0.3s;
}

.confirm-button:hover {
  background-color: #27AE60;
}

.decline-button {
  background-color: #E74C3C;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.decline-button:hover {
  background-color: #C0392B;
}

.cashout-list {
  list-style-type: none;
  padding: 0;
}

.cashout-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 15px;
  border-bottom: 1px solid #ddd;
}

.cashout-week {
  font-size: 1.2em;
  font-weight: bold;
  color: #000000;
}

.cashout-sums {
  margin-top: 10px;
}

@media (max-width: 768px) {
  .dashboard-container {
    display: flex;
    flex-direction: column;
    height: 100vh;
    font-family: Arial, sans-serif;
    background: #dddada;
    padding: 10px;
    /* width: 500px; */
  }
  .profile-picture {
    width: 100%;
    height: 100%;
    border-radius: 10%;
    margin-bottom: 15px;
  }

  .flex-container {
    align-items: center;
  }

  .actions {
    flex-direction: column;
    align-items: stretch;
  }

  .appointment-item {
    flex-direction: column;
    align-items: start;
  }
  .appointment-date {
    display: flex;
    flex-direction: row;
    align-items: center;
    text-align: left;
    font-size: 1em;
    font-weight: bold;
    color: #000000;
  }

  .appointment-list {
    list-style-type: none;
    padding: 0;
    width: 100%;
  }

  .appointment-date span {
    margin-right: 5px;
  }

  .appointment-details {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-left: 0;
  }

  .appointment-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }

  .appointment-info {
    margin-top: 5px;
    text-align: left;
  }

  .appointment-cost {
    font-size: 1em;
    font-weight: bold;
    color: #000000;
    margin-top: 10px;
  }

  .appointment-actions {
    display: flex;
    justify-content: flex-start;
    margin-top: 10px;
  }

  .appointment-status {
    padding: 5px 10px;
    border-radius: 5px;
    font-size: 0.9em;
    margin-left: auto;
  }

  .confirm-button, .decline-button {
    margin-right: 10px;
    font-size: 0.9em;
  }
  .cashout-item {
    align-items: start;
  }
  
  .metric-card {
    background-color: #cfcfcf;
    border-radius: 10px;
    padding: 10px;
    width: 100%;
    text-align: center;
    color: #000000;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;
  }
  
  .metric-card h3 {
    margin-bottom: 10px;
    font-size: 1em;
  }
  
  .metric-card p {
    font-size: 1.5em;
    margin: 0;
  }
  
  .metric-card span {
    font-size: 0.7em;
  }

  .confirm-button {
    background-color: #2ECC71;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    margin-right: 10px;
    transition: background-color 0.3s;
  }
  
  .confirm-button:hover {
    background-color: #27AE60;
  }
  
  .decline-button {
    background-color: #E74C3C;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .decline-button:hover {
    background-color: #C0392B;
  }
  
}
