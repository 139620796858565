.availability-container {
    max-width: 600px;
    margin: auto;
    padding: 20px;
    border: 1px solid #c7c7c7;
    border-radius: 8px;
  }
  
  h2 {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .day-container {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
  }
  
  .day-container input[type='checkbox'] {
    margin-right: 10px;
  }
  
  .day-container label {
    flex: 1;
    margin-right: 10px;
    font-weight: bold;
  }
  
  .day-container input[type='time'] {
    margin-right: 10px;
    padding: 5px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .add-slot {
    padding: 5px 10px;
    margin-left: 10px;
    border: none;
    background-color: transparent;
    cursor: pointer;
    border-radius: 4px;
  }
  
  .add-slot:hover {
    background-color: #e0e0e0;
  }
  
  .submit-btn {
    display: block;
    width: 100%;
    padding: 10px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    margin-top: 20px;
  }
  
  .submit-btn:hover {
    background-color: #0056b3;
  }
  