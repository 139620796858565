.container {
  max-width: 500px;
  min-height: 300px; /* Set a specific height */
  margin: 0 auto;
  padding: 20px;
  border-radius: 10px;
  border: 1px solid #c9c9c9;
}
.business-image, .image-preview img {
  max-width: 100%;
  max-height: 100%;
  margin-top: 10px;
  border-radius: 5px;
}

.suggestions-list {
  list-style-type: none;
  padding: 0;
  margin: 0;
  border: 1px solid #ccc;
  max-height: 200px;
  overflow-y: auto;
}

.suggestion-item {
  padding: 10px;
  cursor: pointer;
}

  .title {
    text-align: center;
    color: #333;
    margin-bottom: 20px;
  }
  
  .form {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }
  
  .input,
  .select {
    min-width: 460px;
    padding: 10px;
    border-radius: 5px;
    background-color: transparent;
    border: 1px solid #ccc;
    font-size: 16px;
  }
  
  .button {
    padding: 10px;
    border-radius: 5px;
    border: none;
    background: #007bff;
    color: white;
    font-size: 16px;
    cursor: pointer;
  }
  
  .button:hover {
    background: #0056b3;
  }
  
  .business-list {
    list-style: none;
    padding: 0;
    margin: 20px 0 0;
  }
  
  .business-item {
    padding: 10px;
    background: white;
    border: 1px solid #ccc;
    border-radius: 5px;
    margin-bottom: 10px;
  }

  .p-register{
    color: #333;
    
  }
  /* Mobile view styles */
@media (max-width: 600px) {
  .container {
    max-width: 300px;
    min-height: 300px; /* Set a specific height */
    margin: 0 auto;
    padding: 20px;
    border-radius: 10px;
    border: 1px solid #c9c9c9;
  }

  .input,
  .select {
    min-width: 260px;
    padding: 10px;
    border-radius: 5px;
    background-color: transparent;
    border: 1px solid #ccc;
    font-size: 16px;
    color: inherit;
  }
}