.professional-register-container {
    display: flex;
    height: 100vh;
    font-family: 'Helvetica Neue', Arial, sans-serif;
    background-color: #f4f4f9;
  }
  
  .left-section {
    width: 50%;
    padding: 40px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: #ffffff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .back-button {
    background: none;
    border: none;
    font-size: 24px;
    cursor: pointer;
    color: #4b9cf3;
    transition: color 0.3s;
  }
  
  .back-button:hover {
    color: #0066cc;
  }
  
  .professional-loginh1 {
    font-size: 36px;
    margin-bottom: 10px;
    color: #333;
  }
  
  .professional-loginp {
    margin-bottom: 20px;
    color: #666;
    line-height: 1.5;
  }
  
  .login-form {
    display: flex;
    flex-direction: column;
  }
  
  .login-form input,
  .continue-button,
  .login-form select {
    width: 100%;
    padding: 12px;
    margin-bottom: 15px;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 16px;
  }
  
  .continue-button {
    background-color: #4b9cf3;
    color: #fff;
    border: none;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .continue-button:hover {
    background-color: #0066cc;
  }
  
  .right-section {
    width: 50%;
    position: relative;
  }
  
  .background-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    opacity: 0.85;
  }
  
  /* Hide the right section on mobile devices */
  @media (max-width: 768px) {
    .professional-register-container {
      flex-direction: column;
    }
  
    .left-section {
      width: 100%;
      box-shadow: none;
    }
  
    .right-section {
      display: none;
    }
  
    .professional-loginh1 {
      font-size: 28px;
      margin-bottom: 20px;
    }
  }
  