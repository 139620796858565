/* src/components/WhatsAppButton.css */

.whatsapp-button {
    position: fixed;
    bottom: 20px;
    right: 20px;
    background-color: #000000;
    color: white;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 2px 5px rgba(0,0,0,0.3);
    z-index: 1000;
    text-decoration: none;
  }
  
  .whatsapp-icon {
    font-size: 60px;
  }
  