.home-container {
  font-family: 'Arial', sans-serif;
  text-align: center;
  background: linear-gradient(to bottom right, #fcfcfc, #ddf2ff);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 0 20px;
}

.home-header {
  width: 100%;
  background-color: transparent;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 40px;
  position: relative;
  top: 0;
}

.logo {
  font-size: 34px;
  font-weight: bold;
  color: #000;
  text-decoration: none;
}

.header-buttons {
  display: flex;
  gap: 20px;
}

.header-button {
  background-color: transparent;
  border: none;
  border-radius: 30px;
  padding: 10px 20px;
  cursor: pointer;
  font-size: 16px;
  font-weight: 600;
  color: #000;
  border: 1px solid #373737;
  margin-right: 9px;
}

.menu-dropdown {
  position: relative;
  display: inline-block;
}

.menu-dropdown .dropdown-content {
  display: none;
  position: absolute;
  background-color: white;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.menu-dropdown:hover .dropdown-content {
  display: block;
}

.home-main {
  margin-top: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.main-heading {
  font-size: 64px;
  font-weight: 800;
  margin-bottom: 30px;
  color: #000;
  line-height: 1.2;
  max-width: 800px;
}

.search-bar {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  border-radius: 30px;
  padding: 10px;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
  margin-bottom: 30px;
  width: 100%;
  max-width: 1000px;
}

.search-bar input {
  padding: 10px;
  margin: 0 5px;
  border: 1px solid #ddd;
  border-radius: 20px;
  width: 100%;
  max-width: 200px;
}

.search-button {
  padding: 10px 30px;
  background-color: black;
  color: white;
  border: none;
  border-radius: 20px;
  cursor: pointer;
  font-size: 16px;
}

.appointments-booked {
  margin-bottom: 30px;
  font-size: 18px;
  color: #000;
}

.get-app-button {
  padding: 10px 20px;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 20px;
  cursor: pointer;
  font-size: 16px;
}

.business-category {
  width: 100%;
  max-width: 1200px;
  margin: 30px 0;
}

.business-items {
  display: flex;
  flex-wrap: wrap;
}

.item {
  background: white;
  border: 1px solid #ddd;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin: 10px;
  width: 350px;
  overflow: hidden;
  cursor: pointer;
  text-align: left;
}

.item img {
  width: 100%;
  height: 200px;
  object-fit: cover;
}

.item-info {
  padding: 15px;
}

.item-info h3 {
  font-size: 18px;
  font-weight: 600;
  margin: 10px 0;
}

.item-info .rating {
  display: flex;
  align-items: center;
  font-size: 14px;
  color: #666;
}

.item-info .rating span:first-child {
  font-weight: 700;
}

.item-info p {
  font-size: 14px;
  color: #666;
  margin: 5px 0;
}

.tag {
  background: #ffffff;
  color: #000000;
  border: none;
  border-radius: 15px;
  padding: 5px 10px;
  font-size: 12px;
  cursor: default;
  border: 1px solid #afafaf;
}

@media (max-width: 768px) {
  .main-heading {
    font-size: 30px;
  }

  .search-bar {
    flex-direction: column;
  }

  .search-bar input {
    width: 100%;
    margin: 5px 0;
  }

  .search-button {
    width: 100%;
    margin-top: 10px;
  }

  .business-items {
    flex-direction: column;
    align-items: center;
  }

  .item {
    width: 90%;
    margin: 10px 0;
  }

  .home-header {
    width: 100%;
    background-color: transparent;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 10px;
    position: relative;
    top: 0;
  }
}
