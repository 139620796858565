/* Service.css */
.service-container {
  padding: 20px;
  margin: 50px;
  gap: 20px;
  display: flex;
  justify-content: space-between;
}

.services-list {
  flex: 1;
  margin-right: 20px; /* Space for the cart on the right */
}

.services-grid {
  display: grid;
  grid-template-columns: repeat(4, 166px); /* Four services in a row, each 166px wide */
  gap: 10px;
  border-radius: 138%; /* Adjust border radius for the grid */
}

.service-item {
  background: #fff;
  border: 1px solid #ddd;
  border-radius: 16px; /* Updated border radius */
  padding: 10px;
  cursor: pointer;
  transition: transform 0.2s, background-color 0.3s, color 0.3s;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 120px; /* Updated height */
  position: relative; /* Added for positioning child elements */
}

.service-item.selected {
  background-color: #000;
  color: #fff;
}

.service-item:hover {
  transform: scale(1.05);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.service-details {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.service-details h3 {
  margin: 5px 0 0 0;
  font-size: 12px;
  font-weight: 700;
}
.service-duration {
  font-size: 11px;
  color: #9b9a9a;
}

.service-price {
  position: absolute; /* Positioning the price */
  bottom: 10px; /* Adjust based on padding */
  right: 0px; /* Adjust based on padding */
  font-weight: 700;
  background-color: #e0e0e0;
  padding: 5px;
  border-radius: 6px 0px 0px 6px;
}
.service-details p {
  margin: 5px 0;
}

.service-duration.selected {
  color: white;
}

.service-price.selected {
  color: rgb(65, 65, 65);
}


.cart-summary-desktop {
  width: 400px;
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 500px; /* Ensure the cart height is fixed */
}

.cart-summary-desktop h3 {
  margin-top: 0;
}

.cart-details {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.yourorder{
  margin-bottom: 10px;
}
.profile-info {
  display: flex;
  position: fixed;
  top: 220px;
  align-items: center;
  gap: 10px;
  margin-bottom: 10px;
}

.profile-pic {
  width: 40px;
  height: 40px;
  border-radius: 20%;
}

.cart-item {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  border-radius: 8px;
}

.cart-item-name {
  font-weight: bold;
}

.cart-service {
  margin-left: 10px;
}

.cart-item-price {
  font-weight: bold;
}

.order-summaryservice {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 0px;
}

.subtotal-amount {
  font-weight: bold;
  font-size: 1.2em;
}

.cart-summary-mobileService {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #000;
  color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  cursor: pointer;
  transition: background-color 0.3s;
  z-index: 1000;
}

.cart-summary-mobileService p {
  margin: 0;
  font-size: 1.3em;
  font-weight: 700;
}

.cart-summary-mobileService:hover {
  background-color: #333;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  width: 90%;
  max-width: 500px;
  height: 80%;
  max-height: 700px;
  position: relative;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.close-buttonService {
  background: #d1cece;
  border: none;
  font-size: 1.3em;
  cursor: pointer;
  border-radius: 190px;
  padding: 2px;
  height: 33px;
  width: 34px;
  border: 1px solid #e7e7e7;
}

.cart-details {
  position: fixed;
  top: 280px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.cart-item {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 10px;
  border-radius: 8px;
}

.profile-pic {
  width: 50px;
  height: 50px;
  border-radius: 20%;
}

.cart-item-business {
  color: #797979;
}

.cart-service {
  margin-left: 10px;
}

.cart-item-price {
  margin-left: auto;
  font-weight: bold;
}

.add-guest {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
  padding: 10px;
  background: #f9f9f9;
  border-radius: 8px;
}

.add-guest-button,
.add-guest-add-button {
  background: none;
  border: none;
  cursor: pointer;
}

.add-guest-button {
  font-weight: bold;
}

.add-guest-add-button {
  background: #000;
  color: #fff;
  padding: 5px 10px;
  border-radius: 8px;
}

.order-summaryservice {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 0px;
}

.choose-time-button {
  background-color: #000;
  color: #fff;
  padding: 15px 20px;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-size: 1.2em;
  margin-top: 20px;
  width: 100%;
  transition: background-color 0.3s;
}

.choose-time-button:hover {
  background-color: #333;
}
.category-dropdown {
  margin-bottom: 20px;
}

.category-dropdown select {
  padding: 10px;
  border-radius: 8px;
  border: 1px solid #ddd;
  width: 30%;
  font-size: 16px;
}

@media (max-width: 768px) {
  .service-container {
    position: relative;
    top: -50px;
    left: -60px;
  }
  .category-dropdown {
    margin-bottom: 20px;
  }
  
  .category-dropdown select {
    padding: 10px;
    border-radius: 8px;
    border: 1px solid #ddd;
    width: 50%;
    font-size: 16px;
  }
  
  .services-grid {
    grid-template-columns: repeat(2, 1fr); /* Two services in a row for mobile view */
  }

  .cart-summary-desktop {
    display: none;
  }

  .cart-summary-mobileService {
    display: flex;
    width: 95%;
    height: 50px;
    left: 10px;
    border-radius: 10px;
    bottom: 10px;
  }
  .OntopofTime {
    position: relative;
    margin-top: 80%; /* Add some space between the subtotal and the button */
  }

  .choose-time-button {
    background-color: #000;
    color: #fff;
    padding: 15px 20px;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    font-size: 1.2em;
    width: 100%;
    transition: background-color 0.3s;
    margin-top: -110px; /* Adjust spacing */
  }

  .choose-time-button:hover {
    background-color: #333;
  }

  .profile-info {
    display: flex;
    position: fixed;
    top: 150px;
    align-items: center;
    gap: 10px;
  }

  .services-grid {
    grid-template-columns: repeat(2, 5fr); /* Two services in a row for mobile view */
    justify-items: center; /* Center align services */
    gap: 10px; /* Increase gap between items */
  }

  .service-item {
    width: 170px; /* Adjust the width to make service items bigger */
    height: 160px; /* Adjust the height to make service items bigger */
    padding: 15px; /* Add padding */
    border-radius: 42%; /* Adjust border radius */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Add box shadow */
  }

  .service-details {
    display: flex;
    flex-direction: column;
    justify-content: space-between; /* Space between service name, duration, and price */
    height: 90%;
    width: 100%;
  }

  .service-details h3 {
    margin: 0;
    font-size: 1.1em; /* Slightly larger font size for service name */
    font-weight: bold;
    text-align: left; /* Align service name to the top left */
  }

  .service-details .service-duration {
    margin-bottom: 90px; /* No space between service name and duration */
    font-size: 1em; /* Slightly smaller font size for duration */
    color: #666; /* Gray color for duration */
  }

  .service-price {
    position: absolute; /* Positioning the price */
    bottom: 10px; /* Adjust based on padding */
    right: 0px; /* Adjust based on padding */
    font-weight: 700;
    background-color: #e0e0e0;
    padding: 5px;
    border-radius: 6px 0px 0px 6px;
  }
  .service-price.selected {
    color: white;
    background-color: #5a5a5a;
  }
  .service-duration.selected {
    color: white;
  }
}

@media (min-width: 769px) {
  .cart-summary-mobileService {
    display: none;
  }
}
