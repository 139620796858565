.payment-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    text-align: center;
    font-family: Arial, sans-serif;
  }
  
  .payment-container h1 {
    font-size: 2em;
    margin-bottom: 20px;
  }
  
  .payment-container .fa-spinner {
    color: #2980b9;
  }
  