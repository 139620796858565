.back-button {
    background-color: #f0f0f0;
    border: none;
    padding: 10px 20px;
    margin-bottom: 20px;
    cursor: pointer;
    font-size: 16px;
    color: #333;
    border-radius: 5px;
  }
  
  .back-button:hover {
    background-color: #e0e0e0;
  }
  