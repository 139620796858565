body, html {
    margin: 0;
    padding: 0;
    font-family: Arial, sans-serif;
  }
  
  .register-container {
    display: flex;
    height: 100vh;
    background-color: #f7f7f7;
  }
  
  .form-container {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 2rem;
    background-color: #fff;
  }
  
  .form-container h2 {
    font-size: 24px;
    margin-bottom: 1rem;
  }
  
  .form-container .subtitle {
    font-size: 16px;
    margin-bottom: 2rem;
    text-align: center;
  }
  
  .form-container form {
    width: 100%;
    max-width: 400px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .input-field {
    width: 100%;
    margin-bottom: 1rem;
    padding: 0.75rem;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .checkbox-container {
    width: 100%;
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
  }
  
  .checkbox-container input {
    margin-right: 0.5rem;
  }
  
  .checkbox-container label {
    font-size: 14px;
  }
  
  .checkbox-container a {
    color: #007bff;
    text-decoration: none;
  }
  
  .checkbox-container a:hover {
    text-decoration: underline;
  }
  
  .submit-button {
    width: 100%;
    padding: 0.75rem;
    font-size: 16px;
    color: #fff;
    background-color: #000;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .footer-links {
    margin-top: 2rem;
    display: flex;
    justify-content: center;
    width: 100%;
  }
  
  .footer-links a {
    margin: 0 1rem;
    color: #007bff;
    text-decoration: none;
  }
  
  .footer-links a:hover {
    text-decoration: underline;
  }
  
  .image-container {
    flex: 1;
    background: url('../MediaStores/loginimage.webp') center/cover no-repeat;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .image-container img {
    max-width: 100%;
    height: auto;
    display: block;
  }
  