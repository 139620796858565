/* Active link styling */
nav ul li a.active {
  background-color: #4CAF50; /* Green background for the active link */
  color: white;
}
/* App.css */

/* Resetting margin and padding for the whole document for consistency */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* Styling the navigation bar */

.dropdown-content ul {
  list-style: none;
  padding: 10px 0;
  margin: 0;
}

.dropdown-content li {
  padding: 10px 20px;
  text-align: left;
}

.dropdown-content li:hover {
  background-color: #f1f1f1;
}

.dropdown-content li a {
  color: #333;
  text-decoration: none;
  display: block;
  transition: color 0.3s;
}

.dropdown-content li a:hover {
  color: #494b4e;
}

.dropdown-content button {
  width: 100%;
  text-align: left;
  border: none;
  background: none;
  padding: 10px 20px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.dropdown-content button:hover {
  background-color: #f1f1f1;
}

/* Responsive styling */
@media screen and (max-width: 600px) {
  nav ul li {
    float: none; /* Stack items vertically on smaller screens */
  }
}
