/* SkeletonLoader.css */
.skeleton-container {
    display: grid;
    grid-template-columns: repeat(4, 166px); /* Adjust to match your grid layout */
    gap: 10px;
  }
  
  .skeleton-item {
    background: #f0f0f0;
    border-radius: 16px;
    padding: 10px;
    display: flex;
    flex-direction: column;
    height: 120px;
  }
  
  .skeleton-thumbnail {
    background: #ddd;
    height: 60%;
    border-radius: 16px;
    margin-bottom: 10px;
  }
  
  .skeleton-details {
    display: flex;
    flex-direction: column;
    gap: 5px;
  }
  
  .skeleton-title,
  .skeleton-subtitle,
  .skeleton-price {
    background: #ddd;
    height: 10px;
    border-radius: 4px;
  }
  
  .skeleton-title {
    width: 80%;
  }
  
  .skeleton-subtitle {
    width: 60%;
  }
  
  .skeleton-price {
    width: 40%;
    margin-top: auto;
  }
  @media (max-width: 768px) {
    .skeleton-container {
      grid-template-columns: repeat(2, 1fr); /* Two skeleton loaders per row for mobile view */
      justify-items: center; /* Center align services */
      gap: 10px; /* Increase gap between items */
    }
    
    .skeleton-item {
      width: 170px; /* Adjust the width to make skeleton items bigger */
      height: 160px; /* Adjust the height to make skeleton items bigger */
      padding: 15px; /* Add padding */
      border-radius: 42%; /* Adjust border radius */
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Add box shadow */
    }
  }